import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit';
import { ReviewSheetTemplate } from '../../interface/review-sheet-template';

interface ReviewState {
  templates: ReviewSheetTemplate[];
  hasChanges: boolean;
  proceedAction: boolean;
}

const initialState: ReviewState = {
  templates: [],
  hasChanges: false,
  proceedAction: false,
};

const reviewSlice: Slice = createSlice({
  name: 'review',
  initialState: initialState,
  reducers: {
    setTemplates: (state: ReviewState, action: PayloadAction<ReviewSheetTemplate[]>) => {
      state.templates = action.payload;
    },
    setHasChanges: (state: ReviewState, action: PayloadAction<boolean>) => {
      state.hasChanges = action.payload;
    },
    setProceedAction: (state: ReviewState, action: PayloadAction<boolean>) => {
      state.proceedAction = action.payload;
    },
  },
});

export const { setTemplates, setHasChanges, setProceedAction } = reviewSlice.actions;

export default reviewSlice.reducer;
