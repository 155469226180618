import './index.scss';
import { Paper } from '@mui/material';
import { formatSourceDate } from '../../dashboard/utils';
import { marked } from 'marked';
import { useEffect, useState } from 'react';
import { SummaryDetail } from '../../../interface/summary-detail';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { SubmissionDetailsDTO } from '../../../interface/submission-details-dto';
import { CiEdit } from 'react-icons/ci';
import { Tooltip } from 'react-tooltip';
import AlertComponent from '../../../components/ui/alert';
import { CiFloppyDisk } from 'react-icons/ci';
import { capitalizeWords } from '../../../utils/string-utils';
import {
  isAudioOrVideoMediaType,
  isSubmissionProcessing,
  hasSubmissionError,
} from '../../../helpers/submission-helper';
import * as summaryReducer from '../../../redux/reducers/summaryReducer';
import DetailsEditModal from './details-edit-modal';
import { SubmissionStatus } from '../../../enum/submission-status';

export default function SummaryTab() {
  const [otherSubmissionDetails, setOtherSubmissionDetails] = useState<Array<SummaryDetail>>([]);
  const [infoMessage, setInfoMessage] = useState<string>('');

  const dispatch = useAppDispatch();

  const currentRecord: SubmissionDetailsDTO = useAppSelector(state => state.submission.currentRecord);
  const { submissionDetails } = useAppSelector(state => state.summary);
  const creationDate = formatSourceDate(currentRecord?.sourceDate);
  const { currentSubmissionIndex } = useAppSelector(state => state.submission);

  const fixedSubmissionDetails: Array<SummaryDetail> = [
    {
      title: 'Apply Code:',
      value: currentRecord.applyCode || '',
    },
    {
      title: 'Upload Date:',
      value: creationDate,
    },
    {
      title: 'Submitted by:',
      value: currentRecord.submitterName || currentRecord.submitterOktaId || '',
    },
    {
      title: 'Assigned to:',
      value: currentRecord.assignedToName || '',
    },
    {
      title: 'Status:',
      value: currentRecord.workflowStatus || '',
    },
    {
      title: 'Analyst Decision:',
      value: currentRecord.analystDecision || '',
    },
    {
      title: 'Media Type:',
      value: currentRecord.mediaType || '',
    },
  ];

  const fixedOtherDetails: Array<SummaryDetail> = [
    {
      title: 'Speaker',
      value: currentRecord.speaker,
    },
    {
      title: 'Approved Provider',
      value: currentRecord.approvedProviderName,
    },
    {
      title: 'SKU',
      value: currentRecord.sku,
    },
  ];

  function renderMarkdown(markdownText: string): string {
    // Use marked library to convert Markdown to HTML
    return marked.parse(markdownText).toString();
  }

  useEffect(() => {
    buildSubmissionDetails();
    defineInfoMessage();
  }, [currentSubmissionIndex]);

  useEffect(() => {
    if (isAudioOrVideoMediaType(currentRecord.sourceLocation)) {
      const summaryTextEl = document.getElementById('summary-text');
      const processedSummary = removeSectionByTitle(
        currentRecord?.transcriptSummary || 'No summary to show.',
        'Claims that may have compliance issues',
      );

      if (summaryTextEl) {
        try {
          summaryTextEl.innerHTML = renderMarkdown(processedSummary);
        } catch (error) {
          summaryTextEl.innerHTML = processedSummary;
        }
      }
    }
  }, [currentRecord?.transcriptSummary]);

  function buildSubmissionDetails() {
    dispatch(summaryReducer.setSubmissionDetails(fixedSubmissionDetails));

    // Define constants for property categories
    const hideProperties = ['Submitted By', 'GCS File Reference Number'];
    const thirdLevelProperties = ['Plan', 'Product', 'Prospect', 'General Business'];

    // Extract submission origin properties
    const submissionOriginProperties = currentRecord.submissionOriginProperties;

    // Determine third level values
    const thirdLevelValues = submissionOriginProperties
      .filter(item => thirdLevelProperties.includes(item.propertyName) && item.propertyValue === 'true')
      .map(item => item.propertyName);

    // Determine levels and format them
    const levels = submissionOriginProperties.filter(item => item.propertyName.includes('Level'));
    let levelsValues = levels.map(property => capitalizeWords(property.propertyValue));

    if (thirdLevelValues.length > 0) {
      levelsValues = levelsValues.concat(thirdLevelValues.join(', '));
    }

    const buildedLevels = levelsValues.join(' > ');

    // Separate questions and non-questions, and filter out hidden properties
    const questions = submissionOriginProperties.filter(item => item.propertyName.endsWith('?'));
    const nonQuestions = submissionOriginProperties.filter(
      item => !item.propertyName.endsWith('?') && !levels.includes(item),
    );
    const filteredNonQuestions = nonQuestions.filter(item => !hideProperties.includes(item.propertyName));

    // Concatenate non-questions with questions
    const sortedData = [...filteredNonQuestions, ...questions];

    // Build detailed summary data
    const otherSubmissionDetailsData: SummaryDetail[] = sortedData.map(item => ({
      title: item.propertyName.endsWith('?') ? item.propertyName : `${item.propertyName}:`,
      value: item.propertyValue,
    }));

    fixedOtherDetails.forEach((sd: SummaryDetail) => {
      if (sd.value) {
        otherSubmissionDetailsData.unshift(sd);
      }
    });

    // Add levels data if available
    if (buildedLevels.length > 0) {
      otherSubmissionDetailsData.unshift({
        title: 'Curriculum Segment / Audience: ',
        value: buildedLevels,
      });
    }

    // Set the other submission details
    setOtherSubmissionDetails(otherSubmissionDetailsData);
  }

  function removeSectionByTitle(markdownText: string, sectionTitle: string): string {
    const sections = markdownText.split(/## /);
    const sectionIndex = sections.findIndex(section => section.startsWith(sectionTitle));

    if (sectionIndex !== -1) {
      sections.splice(sectionIndex, 1);
      const updatedMarkdown = sections.join('\n## ');

      return updatedMarkdown.trim() + '\n';
    }

    return markdownText;
  }

  function editDetails() {
    dispatch(summaryReducer.setShowDetailsEditModal(true));
  }

  function defineInfoMessage(): void {
    setInfoMessage('');

    if (isSubmissionProcessing(currentRecord)) {
      setInfoMessage(
        'Submission is being processed. Transcript and review will be available when processing is completed.',
      );
    }

    if (currentRecord.submissionStatus === SubmissionStatus.TRANSCRIPTION_COMPLETED) {
      setInfoMessage('Transcript is ready. Translation and summary will be available when processing is completed.');
    }
  }

  return (
    <div className="summaryTab">
      {infoMessage && <AlertComponent type="info" text={infoMessage} />}
      {hasSubmissionError(currentRecord) && (
        <AlertComponent
          type="danger"
          text={`This submission was submitted with an error: "${currentRecord.statusDetail}"`}
        />
      )}
      <div className="contentCards summary-row">
        <div className="paperSubmission">
          <Paper className="contentCard" variant="outlined">
            <div className="paper-title">
              <span className="paperText">Submission Details</span>
              <CiEdit
                onClick={editDetails}
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Edit assigned and status"
              />
            </div>
            {currentRecord && (
              <div className="paperBody">
                {submissionDetails.map((submissionDetail: SummaryDetail, index: number) => (
                  <p className="submissionDetail" key={index}>
                    <span className="detailTitle">{submissionDetail.title}</span>
                    {submissionDetail.value}
                  </p>
                ))}
              </div>
            )}
          </Paper>
        </div>

        {isAudioOrVideoMediaType(currentRecord.sourceLocation) ? (
          <Paper className="contentCard paperSummary" variant="outlined">
            <div className="paper-title">
              <span className="paperText">Summary</span>
            </div>
            {currentRecord && (
              <div id="summary-text" className="dataParagraph submissionSummary paperBody">
                {currentRecord.transcriptSummary}
              </div>
            )}
          </Paper>
        ) : (
          <Paper className="contentCard paperDownload" variant="outlined">
            <div className="paper-title">
              <span className="paperText">Download</span>
            </div>
            <div>
              <div className="downloadDescription">
                Currently the review of media types other than Audio and Video is not available.
              </div>

              <a className="downloadBox" href={currentRecord.singedSourceUrl} target="_blank">
                <CiFloppyDisk color="#fff" />
                <div className="downloadText">Download</div>
              </a>
            </div>
          </Paper>
        )}

        <Tooltip id="my-tooltip" place="top" />
      </div>

      {currentRecord &&
        currentRecord.submissionOriginProperties &&
        currentRecord.submissionOriginProperties.length > 0 && (
          <Paper className="other-submission-details" variant="outlined">
            <div className="paper-title">
              <span className="paperText">Other Details</span>
            </div>
            <div className="paperBody">
              <div className="submissionDetails">
                {otherSubmissionDetails.map((submissionDetail: SummaryDetail, index) => (
                  <p className="submissionDetail" key={index}>
                    <span className="detailTitle">{submissionDetail.title}</span>
                    {submissionDetail.value}
                  </p>
                ))}
              </div>
            </div>
          </Paper>
        )}
      <DetailsEditModal />
    </div>
  );
}
