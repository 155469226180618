import './index.scss';
import { Button, Modal } from 'react-bootstrap';
import * as reviewReducer from '../../../redux/reducers/reviewReducer';
import { useAppDispatch } from '../../../redux/hooks';

interface ReviewSheetWarnModalProps {
  showModal: boolean;
  onCloseModal: () => void;
  action: () => void;
}

export default function ReviewSheetWarnModal({ showModal, onCloseModal, action }: ReviewSheetWarnModalProps) {
  const dispatch = useAppDispatch();

  function onYesClick() {
    dispatch(reviewReducer.setProceedAction(true));
    action();
    onCloseModal();
  }

  return (
    <Modal className="review-sheet-warn-modal" show={showModal} onHide={onCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title className="modalTitle" id="AnalystDecisionModal">
          Review Sheet Warning
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <p>Making changes will discard any unsaved modifications to the current Review Sheet content.</p>
        <p>Are you sure you want to continue?</p>
      </Modal.Body>
      <Modal.Footer>
        <div className="footer-content">
          <Button className="button yes-button" onClick={onYesClick}>
            Yes
          </Button>
          <Button className="button no-button" onClick={onCloseModal}>
            No
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
