import { DateTime } from 'luxon';

export const tableCols = [
  {
    label: 'Apply Code',
    sort: 'apply_code',
    width: '6%',
  },
  {
    label: 'Entry Date',
    sort: 'entry_date',
    width: '7%',
  },
  {
    label: 'Assigned To	',
    sort: 'assigned_to_name',
    width: '8%',
  },
  {
    label: 'Type',
    sort: 'type_name',
    width: '7%',
  },
  {
    label: 'Title',
    sort: 'title_english',
    className: 'title',
    width: '20%',
  },
  {
    label: 'Approved Provider',
    sort: 'approved_provider',
    width: '10%',
  },
  {
    label: 'SKU',
    sort: 'sku',
    width: '10%',
  },
  {
    label: 'Speaker',
    sort: 'speaker_full_name',
    width: '12%',
  },
  {
    label: 'Status',
    sort: 'form_status',
    width: '10%',
  },
  {
    label: 'Companion result',
    sort: 'model_decision',
    width: '10%',
  },
];

export function formatSourceDate(source_date: any) {
  if (!(source_date && source_date)) return '';
  const sourceDate = new Date(source_date);
  return DateTime.fromJSDate(sourceDate, { zone: 'UTC' }).toFormat('yyyy-MM-dd');
}

export function formatDateToSource(source_date: any) {
  if (!(source_date && source_date)) return '';
  const sourceDate = new Date(source_date);
  return DateTime.fromJSDate(sourceDate, { zone: 'UTC' }).toFormat('yyyy-MM-dd HH:mm:ss');
}
