export function limitText(fileName: string, limit = 45): string {
  if (fileName.length > limit) {
    return fileName.substring(0, limit).trim().concat('...');
  }
  return fileName;
}

export function addBreaks(input: string, n = 20): string {
  const words = input.split(' ');

  let result: string[] = [];

  // Iterate over the words and add <br> after every nth word
  for (let i = 0; i < words.length; i++) {
    result.push(words[i]);
    if ((i + 1) % n === 0) {
      result.push('<br>');
    }
  }

  return result.join(' ');
}
