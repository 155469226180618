import { CircularProgress } from '@mui/material';
import { Modal, Button } from 'react-bootstrap';
import SelectComponent from '../../../../components/ui/select-component';
import { useEffect, useState } from 'react';
import submissionService from '../../../../resources/submission/submission.service';
import * as submissionReducer from '../../../../redux/reducers/submissionReducer';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { SubmissionDetailsDTO } from '../../../../interface/submission-details-dto';
import submissionOptionsService from '../../../../resources/submission-options/submission-options.service';
import AlertComponent from '../../../../components/ui/alert';
import { Assignee } from '../../../../interface/assignee';
import { WorkflowStatus } from '../../../../interface/workflow-status';
import * as summaryReducer from '../../../../redux/reducers/summaryReducer';
import { SummaryDetail } from '../../../../interface/summary-detail';
import { IOption } from '../../../../components/ui/multi-select-dropdown';
import { FormState } from '../../../../interface/form-state';
import notesService from '../../../../resources/notes/notes.service';
import { NoteDTO } from '../../../../interface/note-dto';
import useAuth from '../../../../resources/auth/auth-hook';

const defaultFormState = {
  title: '',
  content: '',
};

export default function DetailsEditModal() {
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [allStatusOptions, setAllStatusOptions] = useState<Array<IOption>>([]);
  const [selectedStatus, setSelectedStatus] = useState<IOption>({ value: '', label: '' });
  const [allUsersOptions, setAllUsersOptions] = useState<Array<IOption>>([]);
  const [selectedAssignedTo, setSelectedAssignedTo] = useState<IOption>({ value: '', label: '' });
  const [updateDetailsErrorMessage, setUpdateDetailsErrorMessage] = useState<string>('');
  const [infoMessage, setInfoMessage] = useState<string>('');
  const [noteCreationData, setNoteCreationData] = useState<FormState>(defaultFormState);

  const currentRecord: SubmissionDetailsDTO = useAppSelector(state => state.submission.currentRecord);
  const { showDetailsEditModal, submissionDetails } = useAppSelector(state => state.summary);
  const dispatch = useAppDispatch();
  const { user } = useAuth();

  useEffect(() => {
    fetchStatus();
    fetchAssignees();
  }, []);

  useEffect(() => {
    handleSelectedAssignedTo(allUsersOptions);
  }, [user]);

  function handleAssignedTo(option: IOption) {
    setSelectedAssignedTo(option);
  }

  function handleStatus(option: IOption) {
    setSelectedStatus(option);
  }

  function closeEditDetailsAlert() {
    setUpdateDetailsErrorMessage('');
  }

  const handleSubmmitModal = async (e: any) => {
    setInfoMessage('');
    setUpdateDetailsErrorMessage('');
    const title = noteCreationData.title;
    const content = noteCreationData.content;

    if (
      currentRecord.workflowStatus === selectedStatus.label &&
      (currentRecord.assignedToName === selectedAssignedTo.label ||
        (currentRecord.assignedToName === null && selectedAssignedTo.label === ''))
    ) {
      setUpdateDetailsErrorMessage(
        'To proceed with the update, please choose different values for at least one of the fields.',
      );
      return;
    }

    if ((title.length > 0 && title.length < 3) || (content.length > 0 && content.length < 3)) {
      setUpdateDetailsErrorMessage('Please make sure both the title and content are at least 3 characters long.');
      return;
    }

    if (title.length > 0 && !content) {
      setUpdateDetailsErrorMessage('Please provide the content for your note.');
      return;
    }

    if (isRequesting) {
      return;
    }

    setIsRequesting(true);

    submissionService
      .updateSubmissionDetails(currentRecord.submissionId, selectedAssignedTo.value, selectedStatus.value)
      .promise.then(() => {
        dispatch(summaryReducer.setShowDetailsEditModal(false));
        dispatch(
          submissionReducer.updateSummaryDetails({
            workflowStatus: selectedStatus.label,
            assignedToName: selectedAssignedTo.label,
          }),
        );

        const updatedSubmissionDetails = submissionDetails.map((detail: SummaryDetail) => {
          if (detail.title === 'Assigned to:') {
            return {
              ...detail,
              value: selectedAssignedTo.label,
            };
          }
          if (detail.title === 'Status:') {
            return {
              ...detail,
              value: selectedStatus.label,
            };
          }
          return detail;
        });

        dispatch(summaryReducer.setSubmissionDetails(updatedSubmissionDetails));

        if (content.length > 0) {
          const newNote: NoteDTO = {
            submissionId: currentRecord.submissionId,
            title: title.length === 0 ? null : title,
            content,
            createdBy: user?.id,
          };

          notesService.createNote(newNote).promise.then(() => {
            setNoteCreationData(defaultFormState);
          });
        }
      })
      .catch((error: Error) => {
        setUpdateDetailsErrorMessage(
          "We couldn't update submissions details at the moment. Please try again in a few minutes.",
        );
        console.error(error.message);
      })
      .finally(() => {
        setIsRequesting(false);
      });
  };

  function fetchStatus() {
    submissionOptionsService.getAllStatus().promise.then((statuses: Array<WorkflowStatus>) => {
      const statusOptions: Array<IOption> = statuses.map((status: WorkflowStatus) => ({
        value: status.id.toString(),
        label: status.status,
      }));

      const sortedStatusOptions = statusOptions.sort((a, b) =>
        a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
      );
      setAllStatusOptions(sortedStatusOptions);

      let currentStatus = statusOptions.find(
        (statusOption: IOption) => statusOption.label === currentRecord.workflowStatus,
      );

      if (currentStatus) {
        setSelectedStatus(currentStatus);
      }
    });
  }

  function fetchAssignees() {
    setInfoMessage('');

    submissionOptionsService.getAllAssignedsAvailable().promise.then((assignees: Array<Assignee>) => {
      const assigneeOptions: Array<IOption> = assignees.map((assignee: Assignee) => ({
        value: assignee.id.toString(),
        label: assignee.name,
      }));

      const sortedAssigneeOptions = assigneeOptions.sort((a, b) =>
        a.label.localeCompare(b.label, 'en', { sensitivity: 'base' }),
      );
      setAllUsersOptions(sortedAssigneeOptions);
      handleSelectedAssignedTo(assigneeOptions);
    });
  }

  function handleSelectedAssignedTo(assigneeOptions: Array<IOption>) {
    const currentAssignee = assigneeOptions.find(
      (assigneeOption: IOption) => assigneeOption.label === currentRecord.assignedToName,
    );

    if (currentAssignee) {
      setSelectedAssignedTo(currentAssignee);
    } else {
      const currentUser = assigneeOptions.find((assigneeOption: IOption) => assigneeOption.label === user?.name);

      if (currentUser) {
        setInfoMessage('The assignee has been selected automatically. Please confirm the change to apply it.');
        setSelectedAssignedTo(currentUser);
      }
    }
  }

  function onClose() {
    dispatch(summaryReducer.setShowDetailsEditModal(false));
    setUpdateDetailsErrorMessage('');
    setNoteCreationData(defaultFormState);
  }

  const handleNoteCreationChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setNoteCreationData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Modal
      className="summaryCustomModal"
      size="sm"
      show={showDetailsEditModal}
      onHide={() => onClose()}
      aria-labelledby="EditDetailsModal">
      <Modal.Header closeButton>
        <Modal.Title className="modalTitle" id="EditDetailsModal">
          Edit Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalBody">
        {updateDetailsErrorMessage && (
          <AlertComponent
            type="danger"
            text={updateDetailsErrorMessage}
            buttonText="X"
            onClick={closeEditDetailsAlert}
          />
        )}
        {infoMessage && (
          <AlertComponent type="info" text={infoMessage} buttonText="X" onClick={() => setInfoMessage('')} />
        )}
        {isRequesting ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '32px',
            }}>
            <CircularProgress color="inherit" size={40} />
          </div>
        ) : (
          <div className="bodyContainer">
            <p>Select the new assigned and status:</p>
            <SelectComponent
              label="Assigned to: "
              name="assigned-to"
              onChange={handleAssignedTo}
              isDisabled={false}
              options={allUsersOptions ?? []}
              selectedOption={selectedAssignedTo}
            />
            <SelectComponent
              label="Status: "
              name="status"
              onChange={handleStatus}
              isDisabled={false}
              options={allStatusOptions ?? []}
              selectedOption={selectedStatus}
            />
            <div className="note-container">
              <p>Add a note:</p>
              <input
                id="title-creation"
                name="title"
                type="text"
                placeholder="Title"
                value={noteCreationData.title}
                onChange={handleNoteCreationChange}
              />
              <textarea
                id="content-creation"
                name="content"
                placeholder="Note"
                onChange={handleNoteCreationChange}
                value={noteCreationData.content}></textarea>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className="button submitButton" onClick={handleSubmmitModal}>
          Confirm Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
